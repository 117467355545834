import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SideMenu.css";
import {faListCheck,faTag,faMagnifyingGlassChart,faBrain,faChartSimple} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";



function SideMenu() {
  const [openIndex, setOpenIndex] = useState(null);
  const [menuExpand, setMenuExpand] = useState(false);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleMouseLeave = () => {
    setOpenIndex(null);
  };

  const isOpen = (index) => {
    return openIndex === index;
  };

  const { t, i18n } = useTranslation("App");

  const isRtl = i18n.language === "he"

  const menuArrowButtonEn = () => {
    setMenuExpand(!menuExpand);
    if (!menuExpand) {
      document.body.style.marginLeft = "220px"
    }
    else {
      document.body.style.marginLeft = "20px"
    }
  }
  const menuArrowButtonHe = () => {
    setMenuExpand(!menuExpand);
    if (!menuExpand) {
      document.body.style.marginRight = "220px"
      document.body.style.marginLeft = "0px"
    }
    else {
      document.body.style.marginRight = "20px"
      document.body.style.marginLeft = "0px"
    }
  }


  return (
    <div
      className={`menu_home ${i18n.language === "he" ? "right" : "left"} ${menuExpand ? "full_size" : "small_size"}`}
      onMouseLeave={handleMouseLeave}
    >
      <button className={`menu_arrow_button ${menuExpand ? "align_end" : "align_center"}`}
        // onClick={isRtl ? menuArrowButtonHe : menuArrowButtonEn}
        >
        {menuExpand ? "\u276E" : "\u276F"}</button>
      <div className="logo">
        <div className="transparent_logo"></div>
        {menuExpand && <img src={'Pictures/small_logo_transparent.png'} alt="logo" />}
      </div>
      {/* <div
      title="Home"
      className={`link ${menuExpand ? "hover_width_fullsize" : "hover_width_small"}`}>
        <FontAwesomeIcon icon={faHouse} color="white" />
        <Link to={"/home"} className={`router_link_home `}>
          {t("home")}
        </Link>
      </div> */}
      {[
        {
          icon: faChartSimple,
          title: `${t("dashboard")}`,
          links: [
            { to: "/dashboard", text: `${t("dashboard")}` },
          ],
        },
        {
          icon: faListCheck,
          title: `${t("finance_management")}`,
          links: [
            { to: "/first", text: `${t("create_financial_report")}` },
            { to: "/second", text: `${t("budgets_control")}` },
            { to: "/third", text: `${t("cash_flow_management")}` },
            { to: "/concentration-debts", text: `${t("concentartion_debts")}` },
          ],
        },
        {
          icon: faTag,
          title: `${t("sales_and_marketing")}`,
          links: [
            { to: "/competitor-analisys-report", text: `${t("competitor_analisys_report")}` },
            { to: "/sales", text: `${t("marketing_startegy")}` },
            { to: "/sales-customer", text: `${t("customer_journey")}` },
            { to: "/advertising-channels", text: `${t("advertising_channels")}` },
            { to: "/sales-effectiveness", text: `${t("marketing_effectiveness_control")}` },
            { to: "/sales-training", text: `${t("sales_training")}` },
            { to: "/sales-managment", text: `${t("sales_managment")}` },
          ],
        },

        {
          icon: faMagnifyingGlassChart,
          title: `${t("management_and_operations")}`,
          links: [
            { to: "/creating-workflow", text: `${t("creating_workflows")}` },
            { to: "/define-rules", text: `${t("create_work_procedure")}` },
            { to: "/confined-work-plan", text: `${t("confined_work_plan")}` },
            { to: "/customer-experience", text: `${t("control_customer_experience")}` },
            { to: "/learning-lessons", text: `${t("learning_lessons")}` },
            { to: "/scenario&risk-managment", text: `${t("scenario_&_risk_managment")}` },

            { to: "/planing-vs-execution", text: `${t("planing_versus_execution")}` },
          ],
        },
        {
          icon: faBrain,
          title: `${t("help_in_making_decisions")}`,
          links:
            [
              { to: "/making-choice", text: `${t("decision_making")}` },
            ]
        },
        // { icon: faPeopleGroup, title: `${t("team_quality")}` },
        // { icon: faUserPlus, title: `${t("manager_expertise")}` },
        // { icon: faUserGear, title: `${t("managerial_skills")}` },
        // { icon: faFaceSmile, title: `${t("personal_characteristics")}` },
      ].map((item, index) => (
        <details
        data-title={item.title}
          key={index}
          className={`details_select
            ${isOpen(index) ? "open" : ""}
            ${menuExpand ? "hover_width_fullsize" : "hover_width_small"}
            `}
          open={isOpen(index)}
        >
          <summary
            className={`
               ${isOpen(index) ? "background_index" : ""}
              ${isRtl ? "summary_he" : "summary_en"}`}
            onClick={(e) => {
              e.preventDefault();
              handleToggle(index);
            }}
          >
            <FontAwesomeIcon icon={item.icon} />
            <span className="router_link_home">{item.title}</span>
          </summary>
          {item.links &&
            item.links.map((link, linkIndex) => (
              <div
             data-title= {link.text}
              className={`link
                ${menuExpand ? "hover_width_fullsize_link" : "hover_width_small_link"}`}
              key={linkIndex}>
                {/* <FontAwesomeIcon icon={link.icon} /> */}
                <Link
                to={link.to}
                className={`router_link_home
                ${menuExpand ? "hover_width_fullsize" : "hover_width_small"}
                ${isRtl ? "margin_right_link" : "margin_left_link"}

                `}
               >
                  {link.text}
                </Link>
              </div>
            ))}
        </details>
      ))}
      {/* <div
      title="Login"
      className={`link ${menuExpand ? "hover_width_fullsize" : "hover_width_small"}`}>
        <FontAwesomeIcon icon={faDoorOpen} color="white" />
        <Link
        to={"/login"}
        className="router_link_home">
          {t("exit")}
        </Link>
      </div> */}
    </div>
  );
}

export default SideMenu;
