import React, { Fragment, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './LoginSignUp.css';
import { useTranslation } from "react-i18next";

function LoginSignUp() {
  const [activeTab, setActiveTab] = useState(0);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [businessField, setBusinessField] = useState('');
  const [establishmentDate, setEstablishmentDate] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [error, setError] = useState('');
  const [isSecondStep, setIsSecondStep] = useState(false);
  const navigate = useNavigate();
  // const dispatch = useDispatch()

  const { t } = useTranslation("Login");

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

   // Utility function to dynamically update the base URL
   const updateBaseTag = (baseURL) => {
    const baseTag = document.querySelector("base") || document.createElement("base");
    baseTag.href = baseURL;
    if (!document.head.contains(baseTag)) {
      document.head.appendChild(baseTag);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: fullName, password }),
        credentials: "include", // Include cookies
      });

      if (response.ok) {
        updateBaseTag(`${process.env.REACT_APP_BACKEND_BASE_URL}/frontend`);
        navigate("/frontend/");
      } else {
        const data = await response.json();
        setError(data.msg || t("Login failed."));
      }
    } catch (error) {
      setError(t("Login failed. Please try again."));
      console.error("Error during login:", error);
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear errors before proceeding

    try {
      const response = await handleRegistrSubmitReq(fullName, password)
      const data = await response.json();
      if (response.ok) {
        navigate('/login');
      } else {
        setError(data.msg); // Show error from the server
      }
    } catch (error) {
      setError('Login failed. Please try again.');
      console.error('Error during login:', error);
    }

    if (!isSecondStep) {
      setIsSecondStep(true); // Move to the second step of the signup form
    } else {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: fullName, email, password }),
          credentials: "include", // Include cookies
        });

        if (response.ok) {
          updateBaseTag(`${process.env.REACT_APP_BACKEND_BASE_URL}/frontend`);
          navigate("/frontend/");
        } else {
          const data = await response.json();
          setError(data.msg || t("Registration failed."));
        }
      } catch (error) {
        setError(t("Registration failed. Please try again."));
        console.error("Error during registration:", error);
      }
    }
  };

  return (
    <div className="entire">
      <div className="text-and-login">
        <div className="logo-section">
          <img src="/Pictures/logo_transparent.png" alt="Six Keys Logo" className="login-logo" />
          <p className="tagline">
            {t("Leading Businesses to")} <strong>{t("Magical")}</strong> {t("Growth")}
          </p>
        </div>

        <div className="login-container">
          <div className="content-box">
            <div className="tabs">
              <button
                className={`tab ${activeTab === 0 ? 'active-tab' : ''}`}
                onClick={() => handleTabChange(0)}
              >
                {t("Sign Up")}
              </button>
              <button
                className={`tab ${activeTab === 1 ? 'active-tab' : ''}`}
                onClick={() => handleTabChange(1)}
              >
                {t("Login")}
              </button>
            </div>

            {activeTab === 0 && (
              <form onSubmit={handleSignUpSubmit} className="form-box">
                {!isSecondStep ? (
                  <Fragment>
                    <input
                      type="text"
                      required
                      placeholder={t("Full Name")}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="email"
                      required
                      placeholder={t("Email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="password"
                      required
                      placeholder={t("Password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="input_field_login"
                    />
                    {error && <p className="error-text">{error}</p>}
                    <button type="submit" className="submit-button">{t("Continue")}</button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <input
                      type="text"
                      required
                      placeholder={t("Business Field")}
                      value={businessField}
                      onChange={(e) => setBusinessField(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="date"
                      required
                      placeholder={t("Business Establishment Date")}
                      value={establishmentDate}
                      onChange={(e) => setEstablishmentDate(e.target.value)}
                      className="input_field_login_date"
                      id="input_field_login_date"
                    />
                    <select
                      required
                      value={companySize}
                      onChange={(e) => setCompanySize(e.target.value)}
                      className="input_field_select"
                    >
                      <option value="">{t("How large is your company?")}</option>
                      <option value="Just me">{t("Just me")}</option>
                      <option value="1-5">1-5</option>
                      <option value="5-25">5-25</option>
                      <option value="25-100">25-100</option>
                      <option value="250-1000">250-1000</option>
                      <option value="1000+">1000+</option>
                      <option value="Prefer not to share">{t("Prefer not to share")}</option>
                    </select>

                    <div className="checkbox-section">
                      <input type="checkbox" id="terms" required />
                      <label htmlFor="terms">
                        {t("By proceeding, you agree to the")}{" "}
                        <Link to="/terms">{t("Terms and Conditions")} {t("and")} {t("Privacy Policy")}</Link>
                      </label>
                    </div>

                    <div className="button-group">
                      <button type="button" className="back-button" onClick={() => setIsSecondStep(false)}>
                        {t("Back")}
                      </button>
                      <button type="submit" className="submit-button">{t("Sign Up")}</button>
                    </div>
                  </Fragment>
                )}
              </form>
            )}

            {activeTab === 1 && (
              <form onSubmit={handleLoginSubmit} className="form-box">
                <input
                  type="text"
                  required
                  placeholder={t("Username")}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="input_field_login"
                />
                <input
                  type="password"
                  required
                  placeholder={t("Password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input_field_login"
                />
                {error && <p className="error-text">{error}</p>}
                <button type="submit" className="submit-button">{t("Login")}</button>
                <div className='forgot_login_section_login'>{t("Forgot your password")}  <span className='forgot_span_login_section_login'>{t("Reset here")}</span></div>
              </form>
            )}
          </div>

          <div className="side-title">
            <h4 className='h4_login'>{t("We help businesses grow faster and better")}</h4>
          </div>

        </div>
      </div>
    </div>
  );
}

export default LoginSignUp;
