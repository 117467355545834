import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
//import { Provider } from "react-redux";
import "./i18n.js";
//import { GoogleOAuthProvider } from "@react-oauth/google";

import Login from "./Pages/Login/Login.js";
import TermsAndConditions from "./Pages/Login/TermsAndConditions.js";
import Layout from "./Pages/Login/layouts/Layout.js";
import Loader from "./Pages/Loader/Loader.js"; 

function App() {
  return (
   // <Provider store={store}>
      //<GoogleOAuthProvider clientId="386932037035-k8v833noqjk7m4***********.apps.googleusercontent.com">
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<Login />} />
                <Route path="/terms" element={<TermsAndConditions />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </Suspense>
      //</GoogleOAuthProvider>
   // </Provider>
  );
}

export default App;
