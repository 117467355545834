import React from "react";
import { useLocation } from "react-router-dom";
import SideMenu from "../../../components/SideMenu/SideMenu";
//import Navbar from "../../components/Navbar/Navbar";
//import { useSelector } from "react-redux";


const Layout = ({ children }) => {
  const location = useLocation();

  const isLoginPage = location.pathname.toLowerCase() === "/login";
  const isSignUpPage = location.pathname.toLowerCase() === "/signup";
  const isTerms= location.pathname.toLowerCase() === "/terms";
  const outPages = !isLoginPage && !isSignUpPage && !isTerms

  //const ai = useSelector((state) => state.income.ai);

  return (
    <div className="app-container">
      {/*outPages && <Navbar />*/}
      <div className="content-container">
      {outPages && <SideMenu />}
        <main className="main-content">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
